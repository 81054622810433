import React from "react";

import { MetaTags } from "components/meta-tags";
import { getUserKind } from "components/testimonial/helpers";
import { useAuth } from "hooks/use-auth";

import { CompareBudgets } from "./compare-budgets";
import { FeaturedIn } from "./featured-in";
import { HeroHome } from "./hero-home";
import { HomeFaqs } from "./home-faqs";
import { HomeRestrictions } from "./home-restrictions";
import { HowItWorks } from "./how-it-works";
import { JoinWaitlist } from "./join-our-waitlist";
import { KeyBenefits } from "./key-benefits";
import { ShariahTrustbar } from "./shariah-trustbar";
import { Testimonial } from "./testimonial";
import { WhereWeBuy } from "./where-we-buy";

export const Home = () => {
    const {
        user: { profile },
    } = useAuth();
    const userKind = getUserKind(profile, "homepage");
    const isShariahUser = userKind === "shariah";

    return (
        <>
            <MetaTags
                title="Wayhome | Gradual homeownership"
                description="Wayhome is the missing step between renting and homeownership. Start with 5% deposit and buy more—fee free—at your own pace."
                isHomePage={true}
            />
            <HeroHome />
            {isShariahUser ? <ShariahTrustbar /> : <FeaturedIn />}
            <JoinWaitlist />
            <CompareBudgets />
            <HowItWorks />
            <Testimonial userKind={userKind} />
            <WhereWeBuy />
            <KeyBenefits />
            <HomeFaqs />
            <HomeRestrictions />
        </>
    );
};
