import { routes } from "config/routes.js";

export const footerLinks = [
    {
        title: "Careers",
        url: `${process.env.REACT_APP_CAREERS_URL}`,
        isExternal: true,
        target: "_blank",
        rel: "noopener noreferrer",
    },
    {
        title: "Press",
        url: `${routes.misc.press.path}`,
    },
    {
        title: "Terms and Conditions",
        url: `${routes.misc.termsConditions.path}`,
    },
    {
        title: "Key Considerations",
        url: `${routes.misc.keyConsiderations.path}`,
    },
    {
        title: "Regulatory Information",
        url: "/regulatory-information",
        isExternal: true,
        target: "_self",
    },
    {
        title: "Privacy Policy",
        url: `${routes.misc.privacyPolicy.path}`,
    },
    {
        title: "Cookie Policy",
        url: `${routes.misc.cookiePolicy.path}`,
    },
];
