import { IApplicantData, IUserProfile } from "../../hooks";
import { TPages, TUserKind } from "./types";

type TUser = { kind: TUserKind; value: boolean };

const pagesWithShariah: TPages[] = ["homepage", "get-approved", "you-are-on-the-waitlist"];
const pagesWithSelfEmployment: TPages[] = ["application-process"];

// Users can have multiple kinds set simultaneously, we want to pick the most relevant one
// depending on the page.
export const getUserKind = (profile: Partial<IUserProfile> | null, page: TPages) => {
    const IsSelfEmployedApplication = profile?.applicants?.some(
        (applicant: IApplicantData) => applicant.jobStatus === "Self-employment",
    );

    const shariah: TUser = { kind: "shariah", value: profile?.application?.signUpReason?.shariah || false };
    const selfEmployed: TUser = {
        kind: "self-employed",
        value: IsSelfEmployedApplication || false,
    };
    const generic: TUser = { kind: "generic", value: true };

    let priorityOrderBasedOnPage = [generic];

    if (pagesWithShariah.includes(page)) {
        priorityOrderBasedOnPage = [shariah, selfEmployed, generic];
    }

    if (pagesWithSelfEmployment.includes(page)) {
        priorityOrderBasedOnPage = [selfEmployed, shariah, generic];
    }

    const userKind = priorityOrderBasedOnPage.find((reason) => reason.value)!.kind;

    return userKind;
};
