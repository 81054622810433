import React, { useState } from "react";

import {
    Button,
    ErrorMessage,
    Input,
    Label,
    LoadingContainer,
    ShortParagraphCss,
    VerticalSpacing,
} from "@wayhome-uk/design-system";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { BaseComponent } from "types";
import { object, string } from "yup";

import { PasswordInput } from "components/form/password-input";
import { routes } from "config/routes";
import { useAuth } from "hooks/use-auth";

const validation = object().shape({
    email: string()
        .required("Please fill in the email field")
        .email("The login email is not in the correct format. Please correct and try again"),
    password: string().required("Please fill in the password field"),
});

interface IProps extends BaseComponent {
    onSuccessfulLogin?: (uuid: string, token: string) => void;
    email: string;
    password: string;
}

export const LoginForm: React.FC<IProps> = ({ onSuccessfulLogin, email, password }) => {
    const { isAuthLoading, signIn } = useAuth();
    const [hasLoginError, setHasLoginError] = useState<boolean>(false);

    const handleFormikSubmit = async (values: { email: string; password: string }) => {
        const { email, password } = values;
        const userInfo = await signIn(email, password);
        const token = await userInfo?.fbUser?.getIdToken();

        if (userInfo?.uuid && token) {
            if (onSuccessfulLogin) {
                return onSuccessfulLogin(userInfo.uuid, token);
            }
        } else {
            setHasLoginError(true);
        }
    };

    return (
        <LoadingContainer isLoading={isAuthLoading} loadingMessage="Signing in">
            <Formik onSubmit={handleFormikSubmit} initialValues={{ email, password }} validationSchema={validation}>
                {({ handleSubmit, isValid, touched, errors, setFieldValue, values }) => (
                    <form onSubmit={handleSubmit}>
                        <StyledLabel htmlFor="email">Email</StyledLabel>
                        <StyledInput
                            id="email"
                            name="email"
                            type="text"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("email", event.target.value);
                            }}
                            value={values.email}
                        />

                        <StyledLabel htmlFor="password">Password</StyledLabel>
                        <PasswordInput
                            id="password"
                            name="password"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("password", event.target.value);
                            }}
                            size="small"
                            value={values.password}
                        />

                        <VerticalSpacing size={24} />

                        {!isValid && errors.email && touched.email && (
                            <StyledErrorMessage>{errors.email}</StyledErrorMessage>
                        )}

                        {!isValid && errors.password && touched.password && (
                            <StyledErrorMessage>{errors.password}</StyledErrorMessage>
                        )}

                        {hasLoginError && (
                            <StyledErrorMessage>
                                Your email and password combination are incorrect. Please try again?
                            </StyledErrorMessage>
                        )}

                        <FlexBox>
                            <StyledLink to={routes.product.passwordReset.path}>Reset password?</StyledLink>
                            <Button type="submit">Sign In</Button>
                        </FlexBox>
                    </form>
                )}
            </Formik>
        </LoadingContainer>
    );
};

const FlexBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLink = styled(Link)`
    ${ShortParagraphCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: bold;
`;

const StyledLabel = styled(Label)`
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing4};
`;

const StyledInput = styled(Input)`
    padding-top: ${({ theme }) => theme.spacing12};
    padding-bottom: ${({ theme }) => theme.spacing12};
    margin-bottom: ${({ theme }) => theme.spacing24};
    width: 100%;
`;

const StyledErrorMessage = styled(ErrorMessage)`
    ${ShortParagraphCss};
    margin-bottom: ${({ theme }) => theme.spacing24};
    padding-left: ${({ theme }) => theme.spacing24};

    svg {
        margin-left: -${({ theme }) => theme.spacing24};
        margin-right: 0;
        height: 14px;
    }
`;
